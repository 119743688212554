<template>
  <div v-if="mobileWidth" class="index-mobile" >
    <mobile />
  </div>
  <div v-else-if="padWidth" class="index-pad">
    <pad />
  </div>
  <div v-else class="index-pc">
    <pc />
  </div>
</template>

<script>
import mobile from "./index-mobile";
import pc from "./index-pc";
import pad from "./index-pad";

export default {
  computed: {},
  components: {
    mobile,
    pc,pad
  },
  data() {
    return {
      mobileWidth: window.innerWidth < this.$mobileWidth ,
      padWidth: window.innerWidth >= this.$mobileWidth && window.innerWidth <= this.$padWidth,
      currentWidth: window.innerWidth
    };
  },
  created() {},
  mounted() {
    this.$router.push('/max-sales?contact=true');
  },
  methods: {},
  filters: {}
};
</script>
