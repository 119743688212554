<template>
  <div class="main">
    <div class="box-top"></div>
    <div class="box">
      <div class="main-title-box-p">
        <div class="main-title-box sc-width">
          <div class="top">
            <div class="main-title">Contact Connexima</div>
          </div>
          <div class="content">
            We appreciate your interest in Connexima. Please fill out some
            information below, thanks!
          </div>
        </div>
      </div>

      <div class="box-m"></div>

      <div class="pd-20 sc-width">
        <div class="cards">
          <div class="main-contact">
            <div class="main-left">
              <div class="left-item left-top-item">
                <div class="item">
                  <div class="item-label">Name</div>
                  <div class="item-input item-input-part">
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      v-model="form.user_name"
                    />
                  </div>
                </div>
                <div class="item">
                  <div class="item-label">Email</div>
                  <div class="item-input item-input-part">
                    <input
                      type="text"
                      name="email"
                      placeholder="Your Email"
                      v-model="form.email"
                    />
                  </div>
                </div>
              </div>
              <div class="left-item">
                <div class="item">
                  <div class="item-label">Company (optional)</div>
                  <div class="item-input">
                    <input
                      type="text"
                      name="company_name"
                      placeholder="Your Company"
                      v-model="form.company_name"
                    />
                  </div>
                </div>
              </div>
              <div class="left-item">
                <div class="item">
                  <div class="item-label">Message</div>
                  <div class="item-input">
                    <textarea
                      name="message"
                      placeholder="How can we help?"
                      v-model="form.message"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="left-item" v-if="notSubmit">
                <div class="item-submit search-button" @click="submitForm()">
                  Submit
                </div>
              </div>
              <div class="left-item" v-else>
                <div class="item-submit search-button">
                  Submitting...
                </div>
              </div>
            </div>
            <div class="main-right">
              <div class="right-top">
                <div class="right-top-item right-top-item-left">
                </div>
                <div class="right-top-item">
                </div>
              </div>
              <div class="right-bottom">
                <div class="right-bottom-item">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _createContact } = api;

export default {
  name: "products",
  computed: {},
  data() {
    return {
      loginPop: false,
      confLoading: false,
      showInquire: false,
      form: {
        user_name: "",
        email: "",
        company_name: "",
        message: ""
      },

      loading: false,
      notSubmit: true
    };
  },
  created() {},

  methods: {
    submitForm() {
      this.notSubmit = false;
      _createContact({
        ...this.form
      })
        .then(response => {
          this.$Message.success({
            content: response.msg,
            duration: 10,
            closable: true
          });
          this.form = {
            user_name: "",
            email: "",
            company_name: "",
            message: ""
          };
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.notSubmit = true;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.sc-width {
  /*background: #ffffff;*/
}
.box {
  margin: 50px auto 0 auto;
}
.box-m {
}
.main-title-box {
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*background: #fff;*/
  /*border-bottom: 1px solid #ccc;*/
  margin: 0 auto;
  /*padding-top: 65px;*/
  padding-left: 0;
  padding-right: 0;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 26px 0;
  }
  .content {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 140% */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .main-title {
    font-weight: bold;
    font-size: 33px;
    line-height: 54px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  .exhibitor-sourcing {
    display: flex;
    justify-content: space-between;
  }
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .my-sourcing {
    /*width: 200px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary */

    color: #f57f45;
  }
  .add-sourcing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    width: 241px;
    height: 38px;
    background: #f57f45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }

  .first {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 22px;
    .user {
    }
    .view {
      color: #f57f45;
      margin-left: 5px;
    }
  }
  .instruction {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    .step-item {
      .step-top {
        display: flex;
        .icon {
        }
        .word {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          /* Gray 2 */

          color: #ffffff;
          margin-left: 12px;
          position: relative;
          bottom: 2px;
        }
      }
      .pic {
        margin-top: 10px;
      }
      .first-pic {
        margin-top: 40px;
      }
    }
    .step-line {
    }
  }
  .hide-instruction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}
.main {
  background: #ffffff;
  .box-top {
    height: 27px;
  }
}
.cards {
  .table-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 36px;
  }
}
.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-top: 206px;
    .empty {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cards {
      min-height: 300px;
      position: relative;

      .list-table {
        list-style: none;
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #f2f2f2;
          box-shadow: 0 0 4px #f8f8f8;
          .header {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .wrap1 {
              min-width: 80%;
              cursor: pointer;
              text-align: left;
              .title {
                font-size: 14px;
              }
            }
            .wrap2 {
              text-align: right;
              .status {
                display: inline-block;
                background-color: #ddd;
                padding: 2px 8px;
                margin-bottom: 4px;
                &.pend {
                  background-color: #ddd;
                  color: #333;
                }
                &.acc {
                  background-color: #d5ffd2;
                  color: #418d3a;
                }
                &.rej {
                  background-color: #ffddd9;
                  color: #c04c3a;
                }
              }
              .time {
                color: #888;
              }
            }
          }
          .content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
            .con {
              padding: 10px;
              .tit {
                color: #666;
                padding-bottom: 6px;
              }
              .tip {
                font-size: 12px;
              }
              .doc {
                color: #ff6600;
                a {
                  color: #ff6600;
                }
              }
            }
            .c1 {
              cursor: pointer;
            }
            .c1,
            .c2,
            .c3 {
              flex: 0 0 20%;
            }
            .c4 {
              flex: 0 0 40%;
            }
          }
        }
        .footer {
          padding: 10px;
          text-align: right;
          .numb {
            text-align: left;
            padding: 4px 10px;
            display: inline-block;
            background-color: #ffe7d6;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: center;
      }

      .ivu-poptip-body {
        h4 {
          font-size: 12px;
          text-align: left;
          color: #444;
          padding: 4px 0;
        }
        .tags {
          flex-wrap: wrap;
          display: flex;
          p {
            text-align: left;
            color: #999;
            flex: 0 0 50%;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .list-table {
    .title {
      display: flex;
      height: 42px;
      background: linear-gradient(90deg, #a25d72, #2d3b81);
      opacity: 0.9;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #fefefe;
      line-height: 19px;
      .item {
        padding: 12px 0 0 20px;
        font-size: 18px;
      }
      .product {
        width: 400px;
      }
      .attendee {
        width: 300px;
      }
      .size {
        width: 300px;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      .item {
        height: 80px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          height: 80px;
          justify-content: space-between;
          padding-top: 30px;
          div {
            font-size: 16px;

            font-weight: 500;
            color: #323538;
            line-height: 19px;
            padding-left: 20px;
          }
          .size {
            padding-left: 35px;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
          }
          .size {
            width: 300px;
          }
          .date {
            width: 200px;
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
      }
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
.main-contact {
  display: flex;
  justify-content: space-around;
  margin-bottom: 70px;
  .main-left {
    .left-item {
      margin-top: 48px;
      .item {
        .item-label {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          /* identical to box height, or 140% */

          display: flex;
          align-items: center;

          color: #000000;
          margin-bottom: 16px;
        }

        .item-input {
          input {
            width: 100%;
            height: 50px;
            background: #f2f2f2;
            border-radius: 8px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;

            /* Gray 3 */

            color: #828282;
            border: none;
            outline: none;
            padding-left: 19px;
          }
          textarea {
            width: 628px;
            height: 282px;
            background: #f2f2f2;
            border-radius: 8px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;

            /* Gray 3 */

            color: #828282;
            border: none;
            padding-left: 19px;
            padding-top: 19px;
            outline: none;
          }
        }
        .item-input-part {
          input {
            width: 300px;
          }
        }
      }
      .item-submit {
        width: 618px;
        height: 50px;
        left: 138px;
        top: 1237px;

        /* Primary */

        background: #f57f45;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
      }
    }
    .left-top-item {
      display: flex;
      justify-content: space-between;
    }
  }
  .main-right {
    margin-top: 110px;
    .right-top {
      display: flex;
      justify-content: center;
      .right-top-item-left {
        margin-right: 65px;
      }
    }
    .right-bottom {
    }
  }
}
</style>
<style lang="less" scoped>
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 100%;
}
.pd-20 {
  margin: 0 auto;
}
.main-title-box-p {
  margin: 0 auto;
  background: #120D21;
  height: 200px;
}
</style>
